<template>
  <div class="main-wrapper">
    <div class="admin-top">
      <div class="top-left">
        <span class="left-title">任务管理</span>
      </div>
      <div class="top-right">
        <el-button type="primary" @click="addClassifies()">创建任务</el-button>
      </div>
    </div>

    <el-dialog @close = 'closeDialog' :title="isAddBj==1?'创建系统任务':'编辑系统任务'" :visible.sync="dialogFormVisible" width="24%">
      <el-form :model="form">
        <el-form-item label="活动名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="任务描述" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.describe"></el-input>
        </el-form-item>
        <el-form-item label="任务分类" :label-width="formLabelWidth">
<!--          <el-cascader-->
<!--                  v-model="cascaderValue"-->
<!--                  :options="systemTaskList"-->
<!--                  :props="{value:'id',label:'name',children:'subClassification'}"-->
<!--                  @change="cascaderHandleChange"></el-cascader>-->
          <el-select v-model="form.classify" placeholder="请选择" @change="cascaderHandleChange">
            <el-option
                v-for="item in systemTaskList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="任务封面" prop="cove" style="position: relative">
          <span style="position: absolute;top: 20px;left: 0;font-size: 12px;color: #999">358*206</span>
          <el-upload
            style="position: relative"
            class="upload-demo"
            ref="upload"
            action="string"
            accept="image/jpeg, image/png, image/jpg"
            list-type="picture-card"
            :before-upload="onBeforeUploadImage"
            :http-request="UploadImage"
            :on-change="fileChange"
            :file-list="fileList"
          >
            <el-button style="position: relative;" size="small" type="text">
              {{editImg?'重新上传':'点击添加图片'}}
              <span class="addimg">+</span>
            </el-button>
            <img :src="editImg" v-if="editImg" style="width: 150px;height: 150px;position: absolute;left:240px" alt="">
          </el-upload>
        </el-form-item>

<el-form-item label="任务文件" prop="fil">
  <el-upload
  class="upload-demo"
  action="string"
  :before-remove="beforeRemove"
  :http-request="UploadFile"
  multiple
  :limit="3"
  style="position: relative"
  :file-list="fileLss">
  <el-button size="small" type="primary">{{editFile? '重新上传' : '点击上传'}}</el-button>
    <span v-if="editFile" style="position: absolute;left: 160px">{{editFile}}</span>
</el-upload>
</el-form-item>

        <!-- <el-form-item label="任务文件" prop="fil">
          <el-upload
            class="upload-demo"
            ref="upload"
            action="string"
            list-type="picture-card"
            :http-request="UploadImageTwo"
            :on-change="fileChangeTwo"
            :file-list="fileLss"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeLoging">取 消</el-button>
        <el-button type="primary" @click="queding()">确 定</el-button>
      </div>
    </el-dialog>

    <el-table
      :data="taskList"
      style="width: 100%; flex: 1"
      height="1%"
      size="medium"
      :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
      :cell-style="{fontSize: '12px',color: '#333'}"
    >
      <el-table-column prop="cover" label align="right" class-name="student-name" width="100">
        <template slot-scope="scope">
          <img class="coverImg" :src="scope.row.task_cover" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label align="left" class-name="student-name" width="200">
        <template slot-scope="scope">
          <span class="student-name-title text-overflow" >{{scope.row.task_title}}</span>
          <br />
          <span style="color:#999999" class="student-name-title text-overflow">{{scope.row.task_content}}</span>
        </template>
      </el-table-column>

      <el-table-column prop label="附件" align="center" class-name="student-name">
        <template slot-scope="scope">
          <span class="student-name-title" style="color:#1E33E3;text-decoration:underline;" v-for="(item,index) in scope.row.task_file" :key="index"><a
                  :href="item.file_url" target="_blank">{{item.file_name}}</a></span>
        </template>
      </el-table-column>
      <el-table-column prop label="创建时间" align="center" width="140">
        <template slot-scope="scope">
          <span class="student-name-title">{{scope.row.create_time}}</span>
          <br />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="160">
        <template slot-scope="scope">
          <el-button type="text" style="color:#000000" size="small" @click="editRw(scope.row)">编辑</el-button>
          <el-button type="text" style="color:#000000" size="small" @click="deleteRw(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            v-if="taskList.length>0"
            style="margin-bottom: 10px"
            class="pages-center"
            :current-page="adminPages.currentPageNum"
            :page-size="adminPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="adminPages.total"
            @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "TaskManage",
  data() {
    return {
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      },
      editImg:'',
      editFile:'',
      isAddBj: 1, //1是新增弹框，2是编辑
      taskList: [],
      fileList: [],
      fileLss: [],
      fileUrl:'',
      cove: "",
      dialogFormVisible: false, //创建任务弹窗
      form: {
        id:'',
        name: "",
        describe: "", //描述
        classify: "", //分类
        cover: "", //封面
        uploading: [] //上传文件
      },
      imgUrl: "",
      fil: "",

      formLabelWidth: "70px",
      dialogImageUrl: "",
      dialogVisible: false,
      systemTaskList:[],
      cascaderValue:''
    };
  },
  created() {
    this.getTaskList();
    this.getSystemTask();
  },
  methods: {
    closeDialog(){
      this.dialogFormVisible = false;
      this.editFile = '';
      this.editImg = '';
    },
    closeLoging(){
      this.dialogFormVisible = false;
      this.editFile = '';
      this.editImg = '';
    },
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getTaskList()
    },
    //获取任务分类
    getSystemTask(){
      let parms = {};
      this.$http.axiosGetBy(this.$api.classification,parms,(res)=>{
        // console.log(422,res)
        if(res.code==200){
          this.systemTaskList = res.data.data
        }
      })
    },
    cascaderHandleChange(value){
      // console.log(600,value);
      // console.log(601,value[value.length-1])
      console.log(this.form.classify)
    },
    //获取列表
    getTaskList() {
      let param = {
        type:1,
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum //页面显示条数
      };
      this.$http.axiosGetBy(this.$api.systemTaskList, param, res => {
        // console.log(2020, res);
        if (res.code == 200) {
          this.taskList = res.list;
          this.total = this.adminPages.total
        }
      });
    },

    //创建任务
    addClassifies() {
      this.form.id = '';
      this.form.name = '';
      this.form.describe = '';
      this.form.classify = '';
      this.form.cover = '';
      this.cascaderValue = '';
      this.form.uploading = [];
      this.fileList = [];
      this.editImg = '';
      let that = this;
      that.dialogFormVisible = true;
      that.isAddBj = 1;
    },
    onBeforeUploadImage(file) {
      const isIMAGE = file.type === "image/jpeg" || "image/jpg" || "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isIMAGE) {
        this.$message.error("上传文件只能是图片格式!");
        this.fileList = [];
      }
      if (!isLt1M) {
        this.$message.error("上传文件大小不能超过 1MB!");
        this.fileList = [];
      }
      return isIMAGE && isLt1M;
    },

    UploadImage(param) {
      const _this = this;
      let _file = param.file;
      let reader = new FileReader();
      reader.readAsDataURL(_file);
      reader.onload = function (e){
        let image = new Image();
        image.src = e.target.result;
        image.onload = function (){
          let w = image.width;
          let h = image.height;
          if(w !== 358 || h !== 206){
            _this.fileList = [];
            _this.editImg = '';
            _this.$message.warning('请上传尺寸为358px*206px的图片');
          } else {
            const formData = new FormData();
            formData.append("file", param.file);
            _this.$http.axiosPost(
                _this.$api.uploadImg,
                formData,
                res => {
                  // console.log(1222, res);
                  if (res.code == 200) {
                    // console.log(res.url);
                    _this.editImg = '';
                    _this.form.cover = res.data.src;
                    // console.log(that.form.cover);
                  }
                },
                err => {
                  console.log(err);
                }
            );
          }
        }
      }
    },
    UploadFile(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      this.$http.axiosPost(
        this.$api.uploadFile,
        formData,
        res => {
          // console.log(res);
          if(res.code == 200){
            this.fileUrl = res.url;
            this.editFile = '';
            const fileObj = {
              "file_name":param.file.name,
              "file_path":this.fileUrl
            };
            this.form.uploading.push(fileObj);
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    fileChange(file) {
      // console.log(78, file);
      this.$refs.upload.clearFiles(); //清除文件对象
      this.cove = file.raw; // 取出上传文件的对象，在其它地方也可以使用
      this.fileList = [{ name: file.name, url: file.url }]; // 重新手动赋值filstList， 免得自定义上传成功了, 而fileList并没有动态改变， 这样每次都是上传一个对象
      //   this.form.cover = file.url;
    },
    fileChangeTwo(file) {
      // console.log(79,file)
      this.$refs.upload.clearFiles(); //清除文件对象
      this.fil = file.raw; // 取出上传文件的对象，在其它地方也可以使用
      this.form.uploading = file.name;
      this.fileLss.push(file); // 重新手动赋值fileLss， 免得自定义上传成功了, 而fileList并没有动态改变， 这样每次都是上传一个对象
      this.form.uploading=file.name;
      // console.log(this.fileLss);
    },
        //移除之前
    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      },

    //确定添加
    queding() {
      let that = this;
      console.log(339,that.form.cover)
      if(!that.form.name || !that.form.describe || !that.form.cover || !this.form.classify){
        this.$message.warning('必填项不能为空！')
        return
      }
      let formData = new FormData();
      // console.log(this.form.uploading,that.form.name,that.form.describe,that.form.cover,this.form.classify)
      let fileStr = JSON.stringify(this.form.uploading);
        formData.append("task_title", that.form.name);
        formData.append("task_content", that.form.describe);
        formData.append("task_cover", that.form.cover);
        formData.append('file',fileStr);
        formData.append('task_classification',this.form.classify);
        formData.append('task_type',0)
        if(this.form.id != ''){
          formData.append('task_id',this.form.id)
        }
        // console.log(that.form.cover);
        //   formData.append("file", that.form.uploading);
        that.$http.axiosPost(
          that.$api.save,
          formData,
          res => {
            // console.log(res);
            if (res.code == 200) {
              that.$message.success("创建成功");
              that.dialogVisible = false;
              that.dialogFormVisible = false;
              this.getTaskList();
            } else {
              that.$message.warning("创建失败");
            }
          },
          err => {
            console.log(err);
          }
        );

    },
    //编辑
    editRw(row) {
      this.form.id = '';
      this.form.name = '';
      this.form.describe = '';
      this.form.classify = '';
      this.form.cover = '';
      this.cascaderValue = '';
      this.form.uploading = [];
      this.fileList = [];
      this.editImg = '';
      this.form.id = row.task_id;
      this.$http.axiosGetBy(this.$api.taskEdit, {task_id:row.task_id}, res=>{
        // console.log(357,res)
        if(res.code === 200){
          this.form.name = res.info.task_title;
          this.form.describe = res.info.task_content;
          this.editImg = res.info.task_cover;
          this.form.cover = res.info.task_cover_file;
          this.form.classify = res.info.task_type_id;
          // if(res.info.task_file.length){
          //   this.form.uploading = res.info.task_file;
          //   this.editFile = this.form.uploading[0].file_name
          // }
          this.isAddBj = 2;
      } else {
          this.$message.warning(res.msg)
      }
      })
      this.dialogFormVisible = true;
    },
    //删除
    deleteRw(row) {
      let that = this;
      const id = row.task_id;
      let param = {
        task_id: id
      };
      this.$confirm("删除系统任务后不能恢复，确定要删除吗？", "删除任务", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        that.$http.axiosGetBy(that.$api.delTask, param, res => {
          // console.log(res);
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "删除成功"
            });
            that.getTaskList();
          } else {
            that.$message.warning(res.msg);
          }
        });
      });
    }
  }
};
</script>

<style scoped>
/* .el-upload__tip{
  float: right;
} */
.coverImg{
  max-width: 24px;
  max-height: 18px;
}
.addimg {
  display: block;
  width: 22px;
  height: 22px;
  background: #1d33e3;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  left: 34%;
  top: -80%;
}
.student-content {
  color: #999999;
}
</style>